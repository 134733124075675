.alert {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.alert--wrapper{
  border-radius: 20px;
  padding: 10px;
}

.alert--error {
  background-color: var(--alert-error-background);
}

.alert--info {
  background-color: var(--alert-info-background);
}

.alert--warning {
  background-color: var(--alert-warning-background);
}

.alert--success {
  background-color: var(--alert-success-background);
}

.alert--title {
  text-align: left;
  text-transform: capitalize;
}

.alert--message {
  padding-top: 5px;
  text-align: left;
}
