:root {
  --white: #ffffff;
  --gray-4: #bdbdbd;
  --gray-5:#E0E0E0;
  --gray-6: #F2F2F2;
  --black: #000000;
  --pink: #dd04a4;
  --orange: #ffa70e;
  --purple: #4e1a75;
  --turquoise: #28c5f3;
  --blue: #0a06b3;
  --green: #44ad73;

  --alert-error: #f44336;
  --alert-info: #2196f3;
  --alert-warning: #ff9800;
  --alert-success: #4caf50;
  --alert-error-background:#fdecea ;
  --alert-info-background: #e8f4fd; 
  --alert-warning-background: #fff4e5;
  --alert-success-background: #edf7ed;
}
