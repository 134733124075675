@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,800");
@import "./typography";
@import "./colors";

body {
  height: 100vh;
  margin: 0;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  place-items: auto;
  background-color: #E5E5E5;
}

* {
  box-sizing: border-box;
}

html,
#root {
  height: 100vh;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.on {
  color: #000;
}
.off {
  color: #ccc;
}

// Just for now. To be refactored
.application-form,
.thank-you-content {
  margin: auto;
  width: 90%;
  @media (min-width: 1000px) {
    width: 50%;
  }
  position: relative;
  z-index: 0;
}

#root  .application-form  {
  background-color: white;
  padding: 30px;
  }

 #root  .thank-you-content {
    background-color: white;
    padding: 30px;
    height: 100%;
    }
  

.thank-you-footer {
  display: grid;
  grid-template-columns: repeat(3, 30%);
  text-align: center;
  color: black;
  margin-top: 150px;
  grid-gap: 3.33%
}

.u-link {
  span {
    position: relative;
    color: #000000;
    font-weight: 700;
    text-decoration: none solid rgb(0, 0, 0);
    transition: background-color 200ms;
    display: inline-block;
  }

  span::before {
    transition: background-color 0.3s;
    position: absolute;
    left: 0;
    right: 0;
    height: 14px;
    top: 2px;
    color: var(--picton-blue);
    content: "";
    display: inline-block;
    z-index: -1;
  }

  span:hover::before {
    position: absolute;
    left: 0;
    right: 0;
    height: 20px;
    top: 2px;
    background-color: rgba(3, 184, 229);
    color: var(--picton-blue);
    content: "";
    display: inline-block;
  }

  span::after {
    position: absolute;
    left: 0;
    right: 0;
    height: 3px;
    bottom: 0px;
    background-color: rgba(3, 184, 229);
    color: var(--picton-blue);
    content: "";
    display: inline-block;
    z-index: 1;
  }
}


.acceptance-form-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
}

input[type="checkbox"] {
  appearance: none;
}

input[type="radio"] {
  appearance: none;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}
