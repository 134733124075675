.image {
  border: none;

  &--xsmall {
    width: 10%;
  }

  &--small {
    width: 25%;
  }

  &--medium {
    width: 50%;
  }

  &--large {
    width: 100%;
  }

  &--xlarge {
    width: 150%;
  }

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &--left {
    text-align: left;
  }
  
  &--round {
  border-radius: 50%;
  }
}
