.navbar {
  filter: drop-shadow(0px 1px 4px var(--gray-5));
  background-color: var(--white);
  height: 80px;
  position: relative;
  z-index: 1;
}

.navbar-sticky {
  position: sticky;
}

.menu-container {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: left;
}

.menu {
  background: var(--white);
  border-radius: 8px;
  position: absolute;
  top: 60px;
  right: 20px;
  width: 200px;
  box-shadow: 0 1px 4px var(--gray-5);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      border-bottom: 1px solid var(--gray-5);

      a {
        text-decoration: none;
        color: var(--black);
        padding: 15px 20px;
        display: block;

        &:hover {
          text-decoration: bold;
        }

        &.active {
          font-weight: bold;
        }
      }
    }
  }
}
