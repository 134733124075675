
.input-field {
  position: relative;
  line-height: 44px;
}

input::file-selector-button {
  margin-top:10px;
  border: none;
}


.form-input-text, .form-input-text__input, .form-input-file {
  background-color: var(--gray-6);
  border: 2px solid var(--gray-6);
  border-radius: 16px;
  width: 100%;
  outline: 0;
  padding: 0.5rem 0;
  box-shadow: none;
  color: #111;
  font-family: 'Montserrat';
  padding: 10px;
  padding-top: 20px;
  margin-bottom: 30px;
  height: 70px;

  &-error {
  background-color: #FFF3F8;
  border: 2px solid #C30052;
  height: 70px;
  border-radius: 16px;
  width: 100%;
  outline: 0;
  padding: 0.5rem 0;
  box-shadow: none;
  color: #111;
    font-family: 'Montserrat';
    padding: 10px;
    padding-top: 20px;
  }
  &:focus {
    background-color: var(--white);
    border: 2px solid #14142b;
    outline: none;
  }
}

input:focus~ .text-label, textarea:focus~ .text-label, select:focus~ .text-label, 
input:valid~ .text-label, textarea:valid~ .text-label, select:valid~ .text-label,{
  font-size: 14px;
  top: -10px;
  color: black;
}


.input-label-error{
color:#C30052;
margin-top: 0px;
font-size: 10px;

}
.text-label {
  position: absolute;
  top: 5px;
  left: 10px;
  width: 100%;
  transition: 0.2s all;
  cursor: text;
}

.grey{
  background-color: #F2F2F2;
  border-radius: 50%;
  vertical-align: bottom
}

.grey:hover{
  background-color: #E0E0E0
}

select {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  background-size: 22px;
  background-position: 95.7% 50%;
  background-repeat: no-repeat;
}



.form-input-select-wrapper {
    height: 30px;
    width: 120px;

  .form-input-select-header {
    cursor: pointer;
    padding: 0;
    background-color: white;
    border: 2px solid var(--gray-6);
    border-radius: 5px;
  }

  .form-input-select-list-false {
    display: none;
  }

  .form-input-select-list-true {
    position: absolute;
    width: 120px;
    background-color: var(--white);
    z-index: 1;
    box-shadow: 1px 2px 1px 2px var(--gray-6);
    border-radius: 10px;
    padding: 0;
    margin: 0;

    li {
      list-style-type: none;

      button {
        font-family: 'Montserrat';
        font-size: 14px;
        line-height: 20px;
        justify-content: space-between;
        background-color: white;
        border: 0;
        width: 100%;
        text-align: left;

        &:hover,
        &:focus {
          cursor: pointer;
          background-color: var(--light-gray);
        }
      }
    }
  }
}
