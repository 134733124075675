$align: (center, left, right);
$size: (
);

table {
  width: 100%;
  border: solid 1px #E5E5E5;
  background-color: white;
  table-layout:auto;
}

.applicant-info-wrapper  table {
  background-color: transparent;
}

.table-cell{
  padding: 0px 5px;
  @each $value in $align {
    &--align--#{$value} {
      text-align: $value;
    }
  }
    @each $name, $value in $size {
    &--size--#{$name} {
      padding: $value;
    }
}
}

.table-row{
  &--hover{
    background-color: var(--gray-6);
    cursor: pointer;
  }
  &--selected{
        background-color: var(--gray-6);
  }
  a {
    color: var(--black);
  }
}