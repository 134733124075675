@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,800");

:root {
  --font: Montserrat;

  /* set base values */
  --base-size: 18px;
  --scale-ratio: 1.2;
  --scale--ratio: 1.44;
  --scale---ratio: 1.8;

  /* type scale */
  --text-xs: calc(var(--base-size) / (var(--scale--ratio)));
  --text-sm: calc(var(--base-size) / var(--scale-ratio));
  --text-md: calc(var(--base-size) * var(--scale-ratio));
  --text-lg: calc(var(--base-size) * var(--scale--ratio));
  --text-xl: calc(var(--base-size) * var(--scale---ratio));
  --text-xxl: calc(1em * var(--scale---ratio));

  /* line-height */
  --heading-line-height: 1.2;
  --body-line-height: 1.4;

}

body {
  font-size: var(--base-size);
  font-family: var(--font);
  color: var(--black);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--black);
  margin: 6px;
  line-height: var(--heading-line-height);
}

h1,
.text--xxl {
  font-size: var(--text-xxl);
}

h2,
.text--xl {
  font-size: var(--text-xl);
}

h3,
.text--lg {
  font-size: var(--text-lg);
}

h4,
.text--md {
  font-size: var(--text-md);
}

h5,
.text--sm {
  font-size: var(--text-sm);
}

h6,
.text--xs {
  font-size: var(--text-xs);
}

p {
  line-height: var(--body-line-height);
}

/* style */
b,
.bold {
  font-weight: bold;
}

.uppercase {
  text-transform: uppercase;
}

.center {
  text-align: center;
}
.left {
  text-align: left;
}

/* spacing */

.m-a {
  margin: auto;
}

.m-head {
  margin-top: 75px;
  margin-bottom: 25px;
}

.time{
   color: var(--gray-4);
}