.new-button {
  font-family: 'Montserrat';
  font-size: 18px;
  height: 30px;
  width: max-content;
  padding: 0 10px;
  background-color: white;
  border: 2px solid var(--gray-6);
  border-radius: 5px;
}

.button {
  border: 1px solid var(--black);
  border-radius: 40px;
  color: var(--black);

  &--primary {
    background-color: var(--light-gray);

    &:hover {
      background-color: var(--gray-4);
    }

    &:focus {
      box-shadow: 0 0 0 3px var(--gray-6);
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 50%;
    }
  }

  &--secondary , input[type=file]#file-input-button{
    background-color: var(--white);

    &:hover {
      border: 3px solid var(--black);
    }

    &:focus {
      box-shadow: 0 0 0 3px var(--light-gray);
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 50%;
    }
  }

  &--small {
    width: 200px;
    height: 50px;
    font-size: var(--text-sm);
  }

  &--medium {
    width: 200px;
    height: 60px;
    font-size: var(--text-md);
  }

  &--large {
    width: 250px;
    height: 80px;
    font-size: var(--text-lg);
  }
}
