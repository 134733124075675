$spacing: (
  "sm": 2px,
  "md": 16px,
  "lg": 24px,
);

$breakpoints: (
  "sm": 600px,
  "md": 960px,
  "lg": 1280px,
);

$justifyContent: (flex-start, flex-end, center, space-between);
$alignItems: (flex-start, flex-end, center);

@mixin breakpoint($name) {
  @media screen and (min-width: map-get($breakpoints, $name )) {
    @content;
  }
}

.grid {
  &--container {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
  }

  &--item {
    display: block;
  }

  @for $i from 1 to 13 {
    &--xs--#{$i} {
      flex-basis: $i/12 * 100%;
    }
  }

  @include breakpoint("sm") {
    @for $i from 1 to 13 {
      &--sm--#{$i} {
        flex-basis: $i/12 * 100%;
      }
    }
  }

  @include breakpoint("md") {
    @for $i from 1 to 13 {
      &--md--#{$i} {
        flex-basis: $i/12 * 100%;
      }
    }
  }

  @include breakpoint("lg") {
    @for $i from 1 to 13 {
      &--lg--#{$i} {
        flex-basis: $i/12 * 100%;
      }
    }
  }

  @each $name, $value in $spacing {
    &--spacing--#{$name} {
      margin: -$value;
    }

    &--spacing--#{$name} > &--item {
      padding: 2 * $value;
    }
  }

  @each $value in $justifyContent {
    &--justifyContent--#{$value} {
      justify-content: $value;
    }
  }

  @each $value in $alignItems {
    &--alignItems--#{$value} {
      align-items: $value;
    }
  }
}
