.applicant-page-wrapper {
  width: 80%;
  margin: auto;
  text-align: left;
  padding-top: 75px;
}

.applicant-info-wrapper {
  text-align: left;
}
