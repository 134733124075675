.login-wrapper {
  display: block;
  text-align: center;
  margin-top: 15%;

   &--alert {
    margin-top: -10%;
    margin-bottom: 5%;
  }

  &--button {
    margin-top: 5%;
  }
}

.logout{
  text-decoration: none;
  color: var(--black);
  padding: 15px 20px;
  display: block;}